/** @format */

import { combineReducers } from 'redux';
import authDataReducer from 'reducers/authDataReducer';

const rootReducer = combineReducers({
  authData: authDataReducer,
});

export type ReduxState = ReturnType<typeof rootReducer>;

export default rootReducer;

export interface Action {
  type?: string;
  payload: any;
}
