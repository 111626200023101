/** @format */

import { ACTION } from 'actions/types';
import { Action } from 'reducers/rootReducer';
export interface AuthDataReducerState {
  loggedIn: boolean;
}

const teamDataReducerInitialState: AuthDataReducerState = {
  loggedIn: false,
};

export default (
  state: AuthDataReducerState = teamDataReducerInitialState,
  action: Action,
): AuthDataReducerState => {
  const newState = Object.assign({}, state);
  const { payload } = action;

  switch (action.type) {
    case ACTION.LOGIN_USER:
      if (payload.password === 'password123') {
        newState.loggedIn = true;
      }
      return newState;
    default:
      return state;
  }
};
