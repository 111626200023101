/** @format */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { FocusStyleManager } from '@blueprintjs/core';

import configureStore from './store/store';

import './index.scss';
import App from './App.tsx';
import * as serviceWorker from './serviceWorker';
import theme from './styleThemes';

FocusStyleManager.onlyShowFocusOnTabs();

class MainApp extends Component {
  render() {
    return (
      <Provider store={configureStore()}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
